import { messages } from '@danone-global/internal/react/modules/checkout'
import React from 'react'

export const baseEnvironment = {
  storeName: 'NUTRIKLUBCZ',

  siteName: 'cz-nutriklub',

  siteSlug: 'cz-nutriklub',

  defaultLocale: 'cs-CZ',

  providerId: 'danis_eu',

  ctHost: 'https://api.europe-west1.gcp.commercetools.com',

  ctAuthHost: 'https://auth.europe-west1.gcp.commercetools.com',

  ctStoreKey: 'NUTRIKLUBCZ',

  localeConfigs: [
    {
      locale: 'cs-CZ',
      country: 'CZ',
      currency: 'CZK',
      initMessages: () =>
        import('../translations/cs_CZ.json').then((r) => r.default),
      urls: {
        faq: '/produkty-a-nakup/casto-kladene-otazky-k-produktum.html',
        privacy: '/prohlaseni-o-ochrane-osobnich-udaju.html',
        dataCollection: '/data-collection.html',
        termsAndConditions: '/obchodni-podminky.html',
        contact: '/kontakt.html',
      },
    },
  ],

  analytics: {
    tracker: null,
    language: 'cs-CZ',
    defaultCategory: 'Baby food',
  },

  overrides: {
    'shopping-cart-v2': {
      variant: 'badge',
    },
  },

  checkout: {
    stepAddress: React.lazy(
      () =>
        // imports should be on one line, in order to trigger transifex extract actions!
        // prettier-ignore
        import('@danone-global/internal/react/components/checkout/cz-step-address'),
    ),
    payment: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/payment-providers/adyen-drop-in'),
    ),

    checkboxes: [
      {
        name: 'isMarketingOptedIn',
        required: false,
        store: true,
        label: messages.isMarketingOptedIn,
      },
      {
        name: 'agreeTerms',
        required: true,
        store: false,
        label: messages.agreeTerms,
      },
    ],
  },
}
