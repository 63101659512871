import { Features } from '@danone-global/internal/react/core'

export const features: Features = {
  disableTaxInMyOrder: true,
  enableNotifyMe: true,
  showAsteriskByPrice: true,
  showGiftLineItems: true,
  useOrderOnPayment: true,
  useIdentityManagementV2: true,
  showPricePerUnit: true,
}
